import { computed } from "vue";
import { EpPropMergeType } from "element-plus/es/utils";
import { useRoute } from "vue-router";

export function scrollTop(toScrollSelector = "", scrollIfAbove = true) {
  if (toScrollSelector) {
    const destination = document.querySelector(toScrollSelector) as HTMLElement;
    if (destination) {
      const navBar = document.querySelector("#navbar") as HTMLElement;

      const scrollOffset =
        destination.offsetTop - (navBar ? navBar.offsetHeight : 0) - 10;

      if (scrollOffset > window.scrollY && !scrollIfAbove) {
        return;
      }

      window.scrollTo(0, scrollOffset);
    }
  } else {
    window.scrollTo(0, 0);
  }

  return;
}

export function formatDate(date: string, separator = "/", isOffsetMonth: boolean) {
  if (date) {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + (isOffsetMonth ? 1 : 0);
    const year = dateObject.getFullYear();
    return `${appendLeadingZeroes(day)}${separator}${appendLeadingZeroes(
      month
    )}${separator}${year}`;
  }
  return "";
}

export function formatTime(date: string) {
  if (date) {
    const dateObject = new Date(date);
    const hours: string | number = appendLeadingZeroes(dateObject.getHours());
    const minutes: string | number = appendLeadingZeroes(
      dateObject.getMinutes()
    );
    return `${hours}:${minutes}`;
  }
  return "";
}

function appendLeadingZeroes(n: any) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

export function checkStore(
  state: any,
  stateItem: string,
  storeName: string,
  getterName: string
) {
  if (state && state[stateItem] != null) {
    return state[stateItem];
  } else {
    const pinia = localStorage.getItem("pinia");
    const store = JSON.parse(pinia!);
    if (store == null) {
      return null;
    }
    if (store[storeName] == null) {
      return null;
    }
    const storageItem = store[storeName][getterName];
    if (storageItem != null) {
      return storageItem;
    }

    return null;
  }
}

export function statusFilter(status: any) {
  const statusMap: { [key: string]: string } = {
    false: "success",
    true: "danger",
  };
  return statusMap[status] as
    | EpPropMergeType<
        StringConstructor,
        "" | "success" | "warning" | "info" | "danger",
        unknown
      >
    | undefined;
}

function bankersRoundingFunc(num: number, decimalPlaces: number) {
  const d = decimalPlaces || 0;
  const m = Math.pow(10, d);
  const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n),
    f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);

  return d ? (r / m).toFixed(2) : r.toFixed(2);
}

export function thousandSeparator(num: any) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function calculatePrice(price: number, multiplier: number, decimalPlaces = 2) {
  if (price && multiplier) {
    return bankersRoundingFunc(price * multiplier, decimalPlaces);
  } else {
    return (0).toFixed(2);
  }
}

export function tableResizeObserver(_this:any) {
  // This is a hack to remove the "resizeobserver loop limit exceeded" error overlay that appears on screen resize/manipulation
  // Hack source: https://juejin.cn/post/7234703748403839036

  const debounce = (fn: any, delay: any, self: any) => {
    let timer: any = null;
    return function () {
      let context = self;
      let args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    };
  };
  const _ResizeObserver = window.ResizeObserver;
  const self = _this;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback: any) {
      callback = debounce(callback, 16, self);
      super(callback);
    }
  };
}