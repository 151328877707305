import { defineStore } from 'pinia';
import { checkStore } from "@/utilities/index";
import { ElMessage } from "element-plus";
import { handleError } from "@/utilities/api";
import { useAuthStore } from "@/stores/auth";

type UserState = {
  userRole: string | null;
  userExpiry: number | null;
  userId: string | null;
};

export interface StorePartitions {
  "store_id": string,
  "store_name": string
}

export interface Item {
  "sku": string,
  "item_id": string,
  "qty_ordered": number,
  "name": string,
  "order_id": string,
  "original_price": number,
  "price": number,
  "price_incl_tax": number,
  "row_total": number,
  "row_total_incl_tax": number,
  "tax_amount": number,
  "tax_invoiced": number,
  "product_type": string
}

export interface Order {
  "order_id": string,
  "increment_id": string,
  "entity_id": string,
  "store_name": string,
  "created_at": string,
  "updated_at": string,
  "fulfilment_date": string,
  "transmited": true,
  "transmited_at": string,
  "status": string,
  "state": string,
  "customer_firstname": null,
  "customer_lastname": null,
  "grand_total": number,
  "discount_amount": number,
  "subtotal": number,
  "subtotal_incl_tax": number,
  "tax_amount": number,
  "discount_tax_compensation_amount": number,
  "shipping_amount": number,
  "total_due": number,
  "total_item_count": number,
  "user_id": string,
  "cart_id": string,
  "store_code": string | null,
  "store_id": string | null,
  "items": Array<Item>,
  "addressInformation": string | null,
  "extension_attributes": string | null
}

export interface OrderHistory {
  "page_size": number,
  "page_number": number,
  "total_records": number,
  "stores": Array<StorePartitions>, 
  "data": Array<Order>,
  // data: { items: Array<Order> }
}

const getUserInfoFromToken = (token: string) => {
  return (new Promise<any>((resolve, reject) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const parsedJSON = JSON.parse(jsonPayload)
    resolve(parsedJSON);
  }))
}

export const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => {
    return {
      userRole: null,
      userExpiry: null,
      userId: null,
    }
  },
  actions: {
    // Mutations
    setRole(value: string | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["user"]) {
        store["user"] = {};
      }
      store["user"]["role"] = value;
      this.userRole = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
    },
    setExpiry(value: number | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["user"]) {
        store["user"] = {};
      }
      store["user"]["expiry"] = value;
      this.userExpiry = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
    },
    setId(value: string | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["user"]) {
        store["user"] = {};
      }
      store["user"]["id"] = value;
      this.userId = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
    },

    // Actions
    getUserInfo: function (token: string) {
      return new Promise<void>(async (resolve, reject) => {
        // TODO: decode auth token
        // const data = await getUserInfoFromToken(token);

        // if (!data) {
        //   throw Error('Verification failed, please Login again.')
        // }

        // let role = data['role']
        // let expiry = data['exp']
        // let id = data['sub']

        // this.setRole(role);
        // this.setExpiry(expiry * 1000);
        // this.setId(id);

        resolve()
      });
    },
    clearUser: async function () {
      this.setRole(null);
      this.setExpiry(null);
      this.setId(null);
      localStorage.clear();
    },
    getProfile: function () {
      return new Promise<void>(async (resolve, reject) => {
        const authStore = useAuthStore();
        const tkn = authStore.token;
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        };
        await fetch(process.env.VUE_APP_ROOT_API + "/user/me?token=" + tkn.authenticationToken + "&" + process.env.VUE_APP_PARTITION, options)
        .then(async (res) => {
          var resbody = null;
          if (res.ok) {
            resbody = await res.json();
          }

          const isSuccess = res.status < 400 && resbody && resbody.code < 400;
          if(isSuccess) {
            resolve(resbody);
          }
          else {
            if(!resbody) {
              resbody = res;
            }
            authStore.logout();
            console.error("PROFILE FAILED", resbody);
            await handleError(resbody);
            reject(new Error(resbody.result));
          }
        })
        .catch((error) => {
          authStore.logout();
          console.error("PROFILE FAILED", error);
          handleError(error);
          reject(error);
        });
      });
    },
    updateProfile: function (formData: any) {
      return new Promise<void>(async (resolve, reject) => {
        const authStore = useAuthStore();
        const tkn = authStore.token;
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            customer: formData
          })
        };
        await fetch(process.env.VUE_APP_ROOT_API + "/user/me?token=" + tkn.authenticationToken + "&" + process.env.VUE_APP_PARTITION, options)
        .then(async (res) => {
          var resbody = null;
          if (res.ok) {
            resbody = await res.json();
          }

          const isSuccess = res.status < 400 && resbody && resbody.code < 400;
          if(isSuccess) {
            resolve(resbody);
          }
          else {
            if(!resbody) {
              resbody = res;
            }
            console.error("PROFILE FAILED", resbody);
            await handleError(resbody);
            reject(new Error(resbody.result));
          }
        })
        .catch((error) => {
          console.error("PROFILE FAILED", error);
          handleError(error)
          reject(error);
        });
      });
    },
    getOrders: function (page: number = 1, pageSize: number = 20, storeID: string | undefined = "", partitionKey: string | undefined = "") {
      return new Promise<OrderHistory>(async (resolve, reject) => {
        const PageOffset = page - 1;
        const SizeOffset = page > 1 ? PageOffset * (pageSize/2) : 0;

        const authStore = useAuthStore();
        const tkn = authStore.token;
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${tkn.authenticationToken}`
          }
        };
        await fetch(process.env.VUE_APP_ROOT_API + "/manage/orderhistory?token=" + tkn.authenticationToken + "&pageSize=" + pageSize + "&pageNumber=" + page + `${partitionKey ? "&partitionKey=" + partitionKey : ''}` + `${storeID ? "&storeId=" + storeID : ''}`, options)
        .then(async (res) => {
          var resbody = null;
          resbody = await res.json();

          if(resbody.code == 200) {
            resolve(resbody.result);
          }
          else {
            if(!resbody) {
              resbody = res;
            }
            console.error("ORDER FETCH FAILED", resbody);
            await handleError(resbody);
            reject(new Error(resbody.result));
          }
        })
        .catch((error) => {
          console.error("ORDER FETCH FAILED", error);
          handleError(error)
          reject(error);
        });
      });
    },
    getAllOrderInfo: function (storeID: string | undefined = "") {
      return new Promise<any>(async (resolve, reject) => {
        const authStore = useAuthStore();
        const tkn = authStore.token;
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${tkn.authenticationToken}`
          },
          body: JSON.stringify({
            "storeID": storeID,
            "orderID": "",
            "skip":0,
            "take":99999
          })
        };
        await fetch(process.env.VUE_APP_ROOT_API_OLD + "/api/Orders/PreviousOrders", options)
        .then(async (res) => {
          var resbody: any = null;
          resbody = await res.json();

          if(resbody) {
            // distinct list of store IDs
            const stores: any = Object.keys(resbody.reduce((r:any,{storeID}: any) => (r[storeID]='', r) , {}));
            
            // object of distinct store IDs and Names
            stores.forEach((item: any, i: number) => {
              stores[i] = {
                storeID: item,
                storeName: resbody.filter((order: any) => order.storeID.includes(item))[0].storeName
              };
            });
            const orderMeta = {
              storeFilter: stores,
              total: resbody.length
            }
            resolve(orderMeta);
          }
          else {
            if(!resbody) {
              resbody = res;
            }
            console.error("ORDER INFO FETCH FAILED", resbody);
            await handleError(resbody);
            reject(new Error(resbody.result));
          }
        })
        .catch((error) => {
          console.error("ORDER INFO FETCH FAILED", error);
          handleError(error)
          reject(error);
        });
      });
    },
  },
  getters: {
    role(state) {
      return checkStore(state, "userRole", "user", "role");
    },
    expiry(state) {
      return checkStore(state, "userExpiry", "user", "expiry");
    },
    id(state) {
      return checkStore(state, "userId", "user", "id");
    },
  }
})
