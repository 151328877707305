import { defineStore } from "pinia";
import { checkStore } from "@/utilities/index";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import { ElMessage } from "element-plus";
import { handleError } from "@/utilities/api";
import { TokenResultDto, ApiAccountLoginPostRequest, ApiAccountRegisterPostRequest, ApiAccountConfirmEmailPostRequest } from "@/types";

type AuthState = {
  authToken: TokenResultDto | null;
  isLoggedIn: boolean;
};

let _refreshingToken: Promise<TokenResultDto> | null = null;

export const useAuthStore = defineStore({
  id: "auth",
  state: (): AuthState => {
  return {
      authToken: null, // use getter straight out of localstorage
      isLoggedIn: checkStore(this, "authToken", "auth", "token") != null,
    };
  },
  actions: {
    // Mutations
    setToken(value: TokenResultDto | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["auth"]) {
        store["auth"] = {};
      }
      store["auth"]["token"] = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
      this.authToken = value || null;
      this.isLoggedIn = value ? true : false;
    },

    // Actions
    login: function (params: ApiAccountLoginPostRequest) {
      return new Promise<void>(async (resolve, reject) => {
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: params.email,
            password: params.password,
          })
        };
        await fetch(process.env.VUE_APP_ROOT_API + "/user/login?" + process.env.VUE_APP_PARTITION, options)
        .then(async (res) => {
          var resbody = null;
          if (res.ok) {
            resbody = await res.json();
          }

          const isSuccess = res.status < 400 && resbody && resbody.code < 400;
          if(isSuccess) {
            const userStore = useUserStore();
            let token = {
              authenticationToken: resbody.result,
              refreshToken: resbody.meta.refreshToken
            };
            this.setToken(token);

            if(token.authenticationToken) {
              await userStore.getUserInfo(token.authenticationToken)
            }
            resolve();
          }
          else {
            if(!resbody) {
              resbody = res;
            }
            console.error("LOGIN FAILED", resbody);
            if (resbody) {
              if(resbody.code === 403 || resbody.status === 403) {
                ElMessage.error({ message: "Incorrect email or password.", showClose: true, grouping: true });
              }
            }
            await handleError(resbody);
            reject(new Error(resbody.result));
          }
        })
        .catch((error) => {
          console.error("LOGIN FAILED", error);
          if (error) {
            if(error.status === 403) {
              ElMessage.error({ message: "Incorrect email or password.", showClose: true, grouping: true });
            }
          }
          handleError(error)
          reject(error);
        });
      });
    },
    logout: function () {
      return new Promise<void>((resolve, reject) => {
        const userStore = useUserStore();
        this.setToken(null);
        userStore.clearUser().then(() => {
          router.push({name: "Login"});
          resolve()
        });
      });
    },
    refresh: function () {
      return (_refreshingToken ??= new Promise<TokenResultDto>((resolve, reject) => {
        const authenticationToken = this.token?.["authenticationToken"];
        const refreshToken = this.token?.["refreshToken"];
        if(authenticationToken && refreshToken) {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    authenticationToken: authenticationToken,
                    refreshToken: refreshToken,
                })
            };
            fetch(process.env.VUE_APP_ROOT_API_OLD + "/oauth/token", options)
            .then(async (res) => {
                console.log(res)
                // if(res.data) {
                //     debugger
                //     const userStore = useUserStore();
                //     let token = res.data;
                //     this.setToken(token);

                //     if(token.authenticationToken) {
                //         await userStore.getUserInfo(token.authenticationToken)
                //     }

                //     resolve(token);
                // }
            })
            .catch(async (error) => {
                await this.logout().then(() => {
                    ElMessage.error({ message: "Please login.", showClose: true, grouping: true });
                    router.push({ name: 'Login', query: { returnUrl: router.currentRoute.value.fullPath || undefined  } });
                })
                console.error("Refresh FAILED", error);
                reject(error);
            });
        } else {
            reject()
        }
      }).finally(() => (_refreshingToken = null)));
    },
    register: (params: ApiAccountRegisterPostRequest) => {
      return new Promise<void>((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: params.email,
                password: params.password,
            })
        };
        fetch(process.env.VUE_APP_ROOT_API + "/user/create", options)
        .then(() => {
            resolve();
        })
        .catch((error) => {
            console.error("REGISTER FAILED", error);
            handleError(error)
            reject(error);
        });
      });
    },
    resetPassword: (emailAddress: string, passwordResetToken: string, newPassword: string) => {
      return new Promise<void>((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: emailAddress,
                passwordResetToken: passwordResetToken,
                newPassword: newPassword,
            })
        };
        fetch(process.env.VUE_APP_ROOT_API + "/user/login", options)
        .then((res) => {
            console.log(res)
            if ([200, 201, 204].includes(res.status)) {
                resolve();
            } else {
                reject(res);
            }
        })
        .catch((error) => {
            console.log("RESET PASSWORD FAILED", error);
            handleError(error)
            reject(error);
        });
      });
    },
    forgotPassword: (emailAddress: string) => {
      return new Promise<void>((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: emailAddress
            })
        };
        fetch(process.env.VUE_APP_ROOT_API + "/user/reset-password?" + process.env.VUE_APP_PARTITION, options)
        .then((res) => {
            if ([200, 201, 204].includes(res.status)) {
                resolve();
            } else {
                reject(res);
            }
        })
        .catch((error) => {
            console.log("FORGOT PASSWORD FAILED", error);
            handleError(error)
            reject(error);
        });
      });
    },
    emailVerification: (params: ApiAccountConfirmEmailPostRequest) => {
      return new Promise<void>((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                params: params
            })
        };
        fetch("confirm email endpoint", options)
        .then((res) => {
            if ([200, 201, 204].includes(res.status)) {
                resolve();
            } else {
                reject(res);
            }
        })
        .catch((error) => {
            console.log("EMAIL VERIFICATION FAILED", error);
            handleError(error)
            reject(error);
        });
      });
    },
  },
  getters: {
    token(state) {
      return checkStore(state, "authToken", "auth", "token");
    },
    isAuthed(state) {
      return state.isLoggedIn;
    }
  },
});
