// import { hasAnyPermisson } from "@/utilities/auth";
import { ElMessage } from "element-plus";
import { useAuthStore } from "@/stores/auth";
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocation,
} from "vue-router";
import { useMiscStore } from '@/stores/misc';
import { useMediaQuery } from '@vueuse/core';
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import ProfileView from "@/views/ProfileView.vue";
import OrdersView from "@/views/OrdersView.vue";
import ForgetMeView from "@/views/ForgetMeView.vue";
import PageNotFoundView from "@/views/PageNotFoundView.vue";

function authGuard(to: RouteLocation) {
  const authStore = useAuthStore();
  if (authStore.isLoggedIn) {
    // logged in so return true
    return true;
  } else {
    // not logged in so redirect to login page with the return url
    const isAnon = to.meta.isAnon;
    if(!isAnon) {
      router.push({ name: "Login", query: { returnUrl: to.fullPath  } });
      // router.push({ name: "Login", query: { returnUrl: !to.fullPath.includes("/blank") ? to.fullPath : undefined  } });
    }
    return false;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { hideSidebar: true, isAnon: true }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
    meta: { hideSidebar: true, isAnon: true }
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfileView,
  },
  {
    path: "/orders",
    name: "OrdersView",
    component: OrdersView,
  },
  {
    path: "/forget-me",
    name: "ForgetMe",
    component: ForgetMeView,
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFoundView,
    meta: { hideSidebar: true, isAnon: true }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { savedPosition, behavior: "smooth" };
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to) => {
  const authStore = useAuthStore();

  if(to.name !== "Login") {
    authGuard(to)
  } else if(to.name == "Login" && authStore.isLoggedIn) {
    const redirect = router.currentRoute.value.query.returnUrl;
    if(redirect) {
      console.log(redirect)
      // router.push({path: redirect})
      router.push({path: `${!redirect.includes('/blank') ? redirect : '/'}`})
    } else {
      router.push({name: 'Home'})
    }
  }

  ElMessage.closeAll()
  const miscStore = useMiscStore();
  const isDesktop = useMediaQuery('(min-width: 992px)');
  if(!isDesktop.value && miscStore.isSidebarDisplay) {
    miscStore.isSidebarDisplay = false;
  }
});

export default router;
