<template>
  <footer class="bg-dark footer col-12 d-flex align-items-center">
    <div class="px-3 px-md-0 col-12 col-md-9 m-auto">
      <b class="text-white lh-1"
        ><small>{{ new Date().getFullYear() }} - Powered by Advance</small></b
      >
    </div>
  </footer>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
// .footer-placeholder {
//   height: $footerHeight;
// }

.footer {
  height: var(--footer-height);
  // font-weight: 600;
  // color: $footerText !important;
  // position: absolute;
  // right: 0;
  // bottom: 0;
  // left: 0;
  z-index: 200;
  font-family: "Montserrat", Helvetica, sans-serif;

  // a,
  // .email {
  //   // color: $footerText;

  //   &:hover {
  //     &,
  //     * {
  //       @include media-breakpoint-up(lg) {
  //         text-decoration: underline;
  //         color: $tertiary !important;
  //       }
  //     }
  //   }
  // }
}
</style>
