import { defineStore } from "pinia";
import { checkStore } from "@/utilities/index";

type MiscState = {
  isLoading: boolean;
  isSidebarDisplay: boolean;
};

export const useMiscStore = defineStore({
  id: "misc",
  state: (): MiscState => {
    return {
      isLoading: false,
      isSidebarDisplay: false,
    };
  },
});
