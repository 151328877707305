import { useAuthStore } from "@/stores/auth";
import { RouteLocationRaw } from "vue-router";

function logout() {
  const authStore = useAuthStore()
  authStore.logout()
}

type NavigationItem = {
  name: string, // Name to display
  route?: RouteLocationRaw, // Optional: Route, can be a route location object or url string
  iconName?: string, // Icon url - @/assets/icons/{{ iconName }}
  action?: Function // Optional: Function to execute on click
}

export const navigationData: NavigationItem[] = [
  {
    name: "Profile",
    route: { name: "Profile" }
  },
  {
    name: "My Orders",
    route: { name: "OrdersView" }
  },
  {
    name: "Forget Me",
    route: { name: "ForgetMe" }
  },
  {
    name: "Logout",
    action: logout
  },
]