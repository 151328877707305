import { ElMessage } from "element-plus";
import { useMiscStore } from '@/stores/misc';
import { ValidateFieldsError } from "async-validator";

export function handleError(error: any, displayMessage = true, defaultMsg = "An unexpected error has occurred.", duration = 3000) {
  console.log(error);

  const defaultError = `<p>${defaultMsg}</p>`;
  let errorMessage = defaultError;

  if(displayMessage) {
    if (error && Object.prototype.hasOwnProperty.call(error, "data")) {
      if(error.data?.[""]) {
        errorMessage = formatErrors(error.data?.[""])
      } else {
        errorMessage = `<p>${(error.data)?.title || (error.data)?.detail  || defaultError}</p>`;
      }

      if(error.data?.errors) {
        errorMessage = formatErrors(error.data?.errors)
      }
    } else if (error) {
      errorMessage = error.statusText || error.result || defaultError;
    }

    ElMessage.error({ message: errorMessage, showClose: true, duration: duration, dangerouslyUseHTMLString: true });
  }
}

function formatErrors(errors:any):string {
  const errorMessages = errors;
  let result = "";

  if(Array.isArray(errors)) {
    let arr: string[] = []
    errors.forEach((e:string) => {
      arr.push(`<p class='m-0'>${e}</p>`);
    })
    result += arr.join("")
  } else {
    if(Object.keys(errorMessages).length) {
      let arr: string[] = []
      Object.keys(errorMessages).forEach((key:any) => {
        arr.push(`<p class='m-0'>${errorMessages[key]}</p>`);
      })
      result += arr.join("")
    }
  }

  return result;
}

// export function handleError(error: ProblemDetails | any, displayMessage = true) {
//   console.log(error);

//   const miscStore = useMiscStore();
//   const defaultError = "An unexpected error has occurred.";
//   let errorMessage = defaultError;

//   setTimeout(() => {
//     miscStore.isLoading = false;
//     miscStore.isLoadingOverride = false;
//   }, 500);

//   if(displayMessage) {
//     if (error && Object.prototype.hasOwnProperty.call(error, "response")) {
//       errorMessage =
//       (error.response?.data as ProblemDetails).title || (error.response?.data as ProblemDetails).detail  || defaultError;
//     } else if (error) {
//       errorMessage = error.message || defaultError;
//     }

//     ElMessage.error({ message: errorMessage, showClose: true, duration: 0, grouping: true });
//   }
// }

export function handleInvalidForm(fields: ValidateFieldsError) {
  if (fields) {
    const field = Object.values(fields)[0];
    if (field[0]?.field) {
      document.getElementsByName(field[0].field)[0]?.focus();
    }
  }
  console.log("invalid");
}
